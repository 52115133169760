import React, { useCallback, useEffect } from 'react'
import Areas from './areas'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRestaurantAreasThunk, fetchRestaurantTablesThunk } from '../../redux/thunk/restaurant/table'
import RestaurantOrders from './orders'
import Tables from './areas/tables'
import Header from '../Header'
import MainWrapper from '../MainWrapper'
import { AreasMobileHeading, AreasMobileWrapper } from './index.styled'
import { getTableOrderPrice, roundPrice, uuidGenerator } from '../../helper'
import { theme } from '../../style/theme'
import ModalTable from './areas/tables/modalTable'
import ModalPayment from './areas/tables/modalPayment'
import ModalOrder from './areas/tables/modalOrder'
import ModalTableSwap from './areas/tables/modalTableSwap'
import ModalSplitBoard from './areas/tables/modalSplitBoard'
import ModalOrderSplit from './areas/tables/modalOrderSplit'
import ModalPaymentType from './areas/tables/modalPaymentType'
import { closeTableOrdersThunk } from '../../redux/thunk/restaurant/order'
import { setRestaurantModalChangeCalculatorIsNeeded, setRestaurantTable } from '../../redux/action/restaurant'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { getRestaurantModalChangeCalculatorIsNeededThunk } from '../../redux/thunk/restaurant/changeCalculator'
import { getRestaurantSettingsDishCoursesThunk, getRestaurantSettingsPresetDiscountsThunk, getRestaurantSettingsWaiterSettingsThunk } from '../../redux/thunk/restaurantSettings'
import { getGiftCouponsPayment, getGiftCouponsSum } from '../../helper/getGiftCouponsPayment'
import ModalTableClosed from './areas/tables/ModalTableClosed'
import { selectDiscountOnTable, selectGiftCouponInOrders, selectProcessedBy } from '../../redux/selector/restaurant'
import PrintReceiptConfirmationModal from '../Elements/modal/PrintReceiptConfirmationModal'
import { convert2RestaurantCustomer } from './areas/tables/modalPaymentType/helper'
import { resetCustomerThunk } from '../../redux/thunk/customer'
import ModalProcessPaymentError from './areas/tables/ModalProcessPaymentError'
import { selectCompanyIndex } from '../../redux/selector/system'
import WaiterCall from './WaiterCall'
import { setCartPaymentGiftCoupons } from '../../redux/action/cartPayment'
import SendToKitchenModal from './SendToKitchenModal'
import ModalTableWaiterNotified from './areas/tables/ModalTableWaiterNotified'
import { getKitchenConfigThunk } from '../../redux/thunk/kitchenMonitor'
import PaidBeforeOrdersModal from './areas/tables/PaidBeforeOrdersModal'

const Restaurant = () => {
	const { table: selectedTable, showSplitModal, areaId } = useSelector(state => state.restaurant)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasGiftCouponInOrders = useSelector(selectGiftCouponInOrders)
	const totalDiscount = useSelector(selectDiscountOnTable(selectedTable?.orders, hasGiftCouponInOrders))
	const isMobile = useWindowBreakPoint(theme.point720)
	const dispatch = useDispatch()
	const showPaidBeforeOrdersModal = useSelector(state => state.restaurant.showPaidBeforeOrdersModal)
	const hasModalLogin = !!waiterAccessToken
	const processedBy = useSelector(selectProcessedBy)
	const companyIndex = useSelector(selectCompanyIndex)

	useEffect(() => {
		dispatch(fetchRestaurantAreasThunk())
		dispatch(getRestaurantSettingsWaiterSettingsThunk('RESTAURANT'))
		dispatch(getRestaurantSettingsPresetDiscountsThunk())
		dispatch(resetCustomerThunk())
		dispatch(getRestaurantSettingsDishCoursesThunk())
		dispatch(getKitchenConfigThunk(companyIndex))
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		dispatch(getRestaurantModalChangeCalculatorIsNeededThunk())

		return () => dispatch(setRestaurantModalChangeCalculatorIsNeeded(false))
		//eslint-disable-next-line
	}, [])

	const resetTable = useCallback(() => {
		dispatch(fetchRestaurantTablesThunk(areaId))
		dispatch(setRestaurantTable(null))
		dispatch(setCartPaymentGiftCoupons([]))
	}, [dispatch, areaId])

	const handlePaymentType = useCallback(async ({ paymentId, paymentMethod, totalDiscount, extraDiscountItem, tips = 0, totalPrice, giftCoupons, customer }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const isTap2PayPaymentMethod = paymentMethod === 'TAP_TO_PAY'
		const paymentWithCoupons = getGiftCouponsPayment(giftCoupons)
		const giftCouponSum = getGiftCouponsSum(giftCoupons)
		const restSum = roundPrice(totalPrice - giftCouponSum)
		const payments = paymentMethod === 'GIFT_COUPON' ? paymentWithCoupons :
			[...paymentWithCoupons, { id: paymentId, method: paymentMethod, total: restSum, payed: false }]
		const extraDiscount = extraDiscountItem.itemId ? extraDiscountItem : null
		const customerData = customer ? convert2RestaurantCustomer(customer) : null
		// eslint-disable-next-line no-console
		console.log(`Close table ${selectedTable?.id} on total price ${totalPrice} by ${paymentMethod} companyIndex=${companyIndex} from deviceType: ${navigator.userAgent} and deviceId ${uuidGenerator()}`)
		await dispatch(closeTableOrdersThunk(selectedTable.id, { paymentId, paymentMethod, totalDiscount, extraDiscount: extraDiscountItem.value, extraDiscountItem: extraDiscount, totalPrice, payments, tips, processedBy, customer: customerData }, hasModalLogin))
		if (!isEcTerminalPaymentMethod && !isTap2PayPaymentMethod) {
			resetTable()
		}
	}, [selectedTable?.id, hasModalLogin, dispatch, processedBy, resetTable, companyIndex])

	return (
		<>
			<Header>
				{!isMobile && <>
					<RestaurantOrders />
					<WaiterCall />
				</>}
			</Header>
			<MainWrapper className={isMobile ? 'restaurant-mob' : ''}>
				{isMobile ?
					<>
						<AreasMobileHeading>Halle auswählen <RestaurantOrders /></AreasMobileHeading>
						<AreasMobileWrapper>
							<Areas>
								<Tables />
							</Areas>
						</AreasMobileWrapper>
					</> :
					<Tables />}

				<ModalTable />
				<ModalPayment />
				<ModalOrder />
				<ModalTableSwap />
				<ModalSplitBoard />
				<ModalOrderSplit />
				<ModalTableClosed />
				<SendToKitchenModal />
				{selectedTable && !showSplitModal &&
					<ModalPaymentType title={'Tisch #' + (selectedTable?.number || '')}
														totalPrice={getTableOrderPrice(selectedTable) || null}
														totalDiscount={totalDiscount}
														handlePaymentType={handlePaymentType}
														resetTable={resetTable} />}
				<PrintReceiptConfirmationModal />
				<ModalProcessPaymentError />
				<ModalTableWaiterNotified />
				{showPaidBeforeOrdersModal && <PaidBeforeOrdersModal />}
			</MainWrapper>
		</>
	)
}

Restaurant.displayName = 'Restaurant'
export default Restaurant
