import React, { useCallback, useEffect, useRef } from 'react'
import CustomerForm from './form'
import DeliveryModals from './modal'
import { DeliveryTableWrap, DeliveryWrapper } from './index.styled'
import DeliveryMap from '../Elements/map'
import Header from '../Header'
import MainWrapper from '../MainWrapper'
import CustomerMenu from './menu'
import { useForm } from 'react-hook-form'
import { formatAddress, getFloat, removeEmptyFields } from '../../helper'
import { storeCustomerThunk, updateCustomerThunk } from '../../redux/thunk/customer'
import { setDeliveryCartCurrentDeliveryCost } from '../../redux/action/deliveryCart'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setToggled } from '../../redux/action/system'
import DeliveryTable from './table'
import { theme } from '../../style/theme'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { setCustomerAddressIsNotFull } from '../../redux/action/customer'
import SettingsTitle from '../Elements/settings/SettingsTitle'
import { useTranslation } from 'react-i18next'
import InfoOrder from './InfoOrder'
import { selectDeliveryCartPeriodConfig } from '../../redux/selector/cart'
import { selectCompanyIndex } from '../../redux/selector/system'

const defaultValues = {
	phoneNumber: null, customerNumber: null, name: null, companyName: null, address: null, streetNumber: null,
	street: null, city: null, postalCode: null, remark: null, deliveryCost: 0, customerAddress: null,
}

const Delivery = React.memo(() => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, trigger, reset, setValue, watch, setFocus, clearErrors } = useForm({ defaultValues })
	const customer = useSelector(store => store.customerStore.customer)
	const deliveryCost = useSelector(store => store.customerStore.deliveryCost)
	const geoData = useSelector(store => store.customerStore.geoData)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isTablet = useWindowBreakPoint(theme.point820)
	const formRef = useRef(null)
	const periodConfig = useSelector(selectDeliveryCartPeriodConfig)
	const formWidth = formRef?.current?.offsetWidth
	const coord = useSelector(state => state.map.coordinatesMarker)
	const companyIndex = useSelector(selectCompanyIndex)

	const handleReset = (values) => {
		reset({ ...defaultValues, ...values })
	}

	useEffect(() => {
		if (customer) {
			handleReset({
				...customer,
				deliveryCost,
				address: customer ? formatAddress(customer.street, customer.streetNumber, customer.postalCode, customer.city) : null,
				customerAddress: {
					city: customer.city || null,
					street: customer.street || null,
					house: customer.streetNumber || null,
					zipCode: customer.postalCode || null,
					latitude: geoData?.lat || null,
					longitude: geoData?.lon || null,
				},
			})

			if (!customer.city || !customer.street || !customer.postalCode || !geoData?.lat || !geoData?.lon) {
				dispatch(setCustomerAddressIsNotFull(true))
			} else {
				dispatch(setCustomerAddressIsNotFull(false))
			}
		} else {
			handleReset()
		}
	}, [customer]) // eslint-disable-line react-hooks/exhaustive-deps

	const navigateToCart = useCallback(() => {
		navigate('/delivery/cart')
		dispatch(setToggled(true))
	}, [dispatch, navigate])

	const onSubmit = useCallback(async (data, isDelivery = true) => {
		let dataValue
		if (isDelivery) {
			dataValue = {
				...data,
				address: formatAddress(data.customerAddress.street, data.customerAddress.house, data.customerAddress.zipCode, data.customerAddress.city),
				city: data.customerAddress.city,
				street: data.customerAddress.street,
				postalCode: data.customerAddress.zipCode,
				streetNumber: data.customerAddress.house,
				geoData: {
					lat: data.customerAddress.latitude,
					lon: data.customerAddress.longitude,
					distance: geoData?.distance, // if distance was calculated
					duration: geoData?.duration,
					image: geoData?.image,
				},
			}
		} else {
			dataValue = {
				phoneNumber: data.phoneNumber,
				name: data.name,
			}
		}
		dataValue = removeEmptyFields(dataValue)
		dataValue.deliveryCost = getFloat(dataValue.deliveryCost)
		if (dataValue.customerNumber && isDelivery) {
			await dispatch(updateCustomerThunk(dataValue))
		} else {
			await dispatch(storeCustomerThunk(dataValue))
		}
		const cartDeliveryCost = periodConfig ? periodConfig.deliveryCost : dataValue.deliveryCost
		dispatch(setDeliveryCartCurrentDeliveryCost(isDelivery ? cartDeliveryCost : 0))
		navigateToCart()
	}, [navigateToCart, geoData, dispatch, periodConfig])

	return (
		<>
			<Header>
				<CustomerMenu {...{ handleSubmit, onSubmit, trigger, watch, navigateToCart }} />
			</Header>
			<MainWrapper>
				<DeliveryWrapper>
					{isTablet && <SettingsTitle marginBottom={isTablet ? '0.57' : '1.875'} icon="delivery">{t('Permissions.page.delivery_service')}</SettingsTitle>}
					{!isTablet &&
						<>
							{coord &&
								<InfoOrder formWidth={formWidth} />}
							{companyIndex !== 'FA-38264' && <DeliveryMap centerToRight={true} />}
						</>}
					<CustomerForm {...{ onSubmit, handleSubmit, register, errors, trigger, handleReset, setValue, watch, setFocus, formRef, clearErrors }} />
				</DeliveryWrapper>
				<DeliveryTableWrap>
					<DeliveryTable />
				</DeliveryTableWrap>
				<DeliveryModals />
			</MainWrapper>
		</>
	)
})

Delivery.displayName = 'Delivery'
export default Delivery
