import { addSubItem, clone, deleteItem, recalculateSubItems } from '../../../helper'
import { ADD_DELIVERY_CART_ITEM, ADD_DELIVERY_CART_REMARK_TO_ITEM, ADD_DELIVERY_CART_SUBITEM, REMOVE_DELIVERY_CART_COUPON, REMOVE_DELIVERY_CART_ITEM, REMOVE_DELIVERY_CART_REMARK, REMOVE_DELIVERY_CART_SUBITEM, SET_DELIVERY_CART_CURRENT_DELIVERY_COST, SET_DELIVERY_CART_DEFAULT_DELIVERY_COST, SET_DELIVERY_CART_FREE_FROM, SET_DELIVERY_CART_GIFT_COUPONS, SET_DELIVERY_CART_ITEM_FOCUS_INDEX, SET_DELIVERY_CART_ITEMS, SET_DELIVERY_CART_MAX_DISTANCE, SET_DELIVERY_CART_MINIMUM_PRICE, SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_DELIVERY_CART_ORDER, SET_DELIVERY_CART_ORDER_TYPE, SET_DELIVERY_CART_PERIOD_CONFIG, SET_DELIVERY_CART_PREORDER_DATE, SET_SHOW_DELIVERY_CONFIG_ERROR, UPDATE_DELIVERY_CART_ITEM } from '../../actionTypes/deliveryCart'

const initialState = {
	orderType: null,
	items: [],
	itemFocusIndex: null,
	maxDistance: null,
	minimumPrice: 0,
	freeFrom: null,
	currentDeliveryCost: 0,
	defaultDeliveryCost: null,
	preOrderDate: null,
	order: null,
	modalChangeCalculatorIsNeeded: false,
	showDeliveryConfigError: false,
	giftCoupons: [],
	periodConfig: null
}

export function deliveryCartReducer(state = initialState, action) {
	switch (action.type) {
		case SET_DELIVERY_CART_ORDER_TYPE:
			return {
				...state,
				orderType: action.value,
			}
		case SET_DELIVERY_CART_ITEMS:
			return {
				...state,
				items: action.value,
			}
		case ADD_DELIVERY_CART_ITEM:
			const itemsCopy = clone(state.items)
			itemsCopy.push(action.value)
			recalculateSubItems(action.value)
			return {
				...state,
				items: itemsCopy,
			}
		case ADD_DELIVERY_CART_SUBITEM:
			const itemsCopy2 = clone(state.items)
			addSubItem(itemsCopy2[action.value.itemIndex], action.value.item)
			recalculateSubItems(itemsCopy2[action.value.itemIndex])
			return {
				...state,
				items: itemsCopy2,
			}
		case REMOVE_DELIVERY_CART_ITEM:
			const itemsWithoutOne = deleteItem([...state.items], action.value)
			recalculateSubItems(itemsWithoutOne)
			return {
				...state,
				items: itemsWithoutOne,
			}
		case REMOVE_DELIVERY_CART_SUBITEM:
			const itemsCopy4 = clone(state.items)
			itemsCopy4[action.value.itemIndex].items.splice(action.value.subItemIndex, 1)
			recalculateSubItems(itemsCopy4[action.value.itemIndex])
			return {
				...state,
				items: itemsCopy4,
			}
		case UPDATE_DELIVERY_CART_ITEM:
			const itemsCopy7 = [...state.items]
			itemsCopy7[action.value.itemIndex] = recalculateSubItems({ ...action.value.item })
			return {
				...state,
				items: itemsCopy7,
			}
		case SET_DELIVERY_CART_ITEM_FOCUS_INDEX:
			return {
				...state,
				itemFocusIndex: action.value,
			}
		case SET_DELIVERY_CART_PREORDER_DATE:
			return {
				...state,
				preOrderDate: action.value,
			}
		case SET_DELIVERY_CART_ORDER:
			return {
				...state,
				order: action.value,
			}
		case SET_DELIVERY_CART_MAX_DISTANCE:
			return {
				...state,
				maxDistance: action.value,
			}
		case SET_DELIVERY_CART_MINIMUM_PRICE:
			return {
				...state,
				minimumPrice: action.value,
			}
		case SET_DELIVERY_CART_FREE_FROM:
			return {
				...state,
				freeFrom: action.value,
			}
		case SET_DELIVERY_CART_CURRENT_DELIVERY_COST:
			return {
				...state,
				currentDeliveryCost: action.value,
			}
		case SET_DELIVERY_CART_DEFAULT_DELIVERY_COST:
			return {
				...state,
				defaultDeliveryCost: action.value,
			}
		case REMOVE_DELIVERY_CART_REMARK:
			const itemsCopy5 = clone(state.items)
			itemsCopy5[action.value].remark = null
			return {
				...state,
				items: itemsCopy5,
			}
		case ADD_DELIVERY_CART_REMARK_TO_ITEM:
			const itemsCopy6 = clone(state.items)
			itemsCopy6[action.value.itemFocusIndex].remark = action.value.comment
			return {
				...state,
				items: itemsCopy6,
			}
		case SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED:
			return {
				...state,
				modalChangeCalculatorIsNeeded: action.value,
			}
		case SET_SHOW_DELIVERY_CONFIG_ERROR:
			return {
				...state,
				showDeliveryConfigError: action.value,
			}
		case REMOVE_DELIVERY_CART_COUPON:
			const itemsCopy8 = [...state.items]?.filter(item => item.type !== 'MARKETING_COUPON')
			return {
				...state,
				items: itemsCopy8,
			}
		case SET_DELIVERY_CART_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: action.value,
			}
		case SET_DELIVERY_CART_PERIOD_CONFIG:
			return {
				...state,
				periodConfig: action.value,
			}
		default:
			return state
	}
}
