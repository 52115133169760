import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CartTotal, CartTotalContent, CartTotalPrice, CartTotalPriceText, CartTotalPriceValue, CartTotalText, CartTotalValue } from '../index.styled'
import Currency from '../../currency'
import DeliveryCost from '../../../Delivery/DeliveryCart/DeliveryCost'
import { useDispatch, useSelector } from 'react-redux'
import { selectCartDiscountInCurrency, selectCouponDiscountInCurrency, selectCouponInCart, selectExtraDiscountInCurrency, selectGiftCouponInCart, selectMoneyExchangeInCart } from '../../../../redux/selector/cart'
import { getBusinessScopeDiscountThunk } from '../../../../redux/thunk/cartDiscount'
import ExtraDiscount from '../ExtraDiscount'
import Discount from '../Discount'

const CartTotals = React.memo(({ totalPrice, type, giftCoupons, orderType, hasWaiterDiscountError, maxDiscountForWaiter, itemsLength, previousIndex }) => {
	const { t } = useTranslation()
	const hasGiftCoupon = useSelector(selectGiftCouponInCart(type))
	const hasMoneyExchange = useSelector(selectMoneyExchangeInCart(type))
	const isDisabled = hasGiftCoupon || hasMoneyExchange
	const isDisabledExtraDiscount = hasGiftCoupon || hasMoneyExchange
	const coupon = useSelector(selectCouponInCart(type))
	const discountInCurrency = useSelector(selectCartDiscountInCurrency(type))
	const extraDiscountInCurrency = useSelector(selectExtraDiscountInCurrency(type))
	const couponDiscountData = coupon?.initialProduct?.discountData
	const discountCouponInCurrency = useSelector(selectCouponDiscountInCurrency(type))
	const couponInPercents = couponDiscountData?.discountType === 'PERCENTS'
	const dispatch = useDispatch()
	const isGiftCoupons = giftCoupons?.length > 0

	useEffect(() => {
		if (orderType && !itemsLength) {
			dispatch(getBusinessScopeDiscountThunk(orderType))
		}
	}, [orderType, itemsLength]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<CartTotal>
			{type === 'DELIVERY' &&
				<CartTotalContent className="border">
					<DeliveryCost {...{ type }} />
				</CartTotalContent>}
			<CartTotalContent className="border-dashed">
				<Discount {...{ type, orderType, isDisabled, discountInCurrency, previousIndex }} />
			</CartTotalContent>
			<CartTotalContent className={(couponDiscountData || isGiftCoupons) ? 'border' : ''}>
				<ExtraDiscount {...{ type, extraDiscountInCurrency, hasWaiterDiscountError, maxDiscountForWaiter, isDisabled: isDisabledExtraDiscount }} />
			</CartTotalContent>
			{couponDiscountData &&
				<CartTotalContent className={(isGiftCoupons ? 'border-dashed ' : '') + ('grid ')}>
					<CartTotalText>{t('Cart.coupon')}:</CartTotalText>
					<CartTotalValue>{couponInPercents ? `${couponDiscountData?.discount}%` : <Currency>{discountCouponInCurrency}</Currency>}</CartTotalValue>
					<CartTotalValue>-<Currency>{discountCouponInCurrency}</Currency></CartTotalValue>
				</CartTotalContent>}
			{giftCoupons?.length > 0 && giftCoupons?.map((item, couponIndex) =>
				<CartTotalContent key={couponIndex} className="grid">
					<CartTotalText>{t('Cart.gift_coupon')}:</CartTotalText>
					<CartTotalValue><Currency>{item.balance}</Currency></CartTotalValue>
					<CartTotalValue>-<Currency>{item.cartBalance}</Currency></CartTotalValue>
				</CartTotalContent>)}
			<CartTotalPrice>
				<CartTotalPriceText>{t('Cart.totalprice')}</CartTotalPriceText>
				<CartTotalPriceValue className="total">
					<Currency>{totalPrice}</Currency>
				</CartTotalPriceValue>
			</CartTotalPrice>
		</CartTotal>
	)
})

CartTotals.displayName = 'CartTotals'
export default CartTotals
