import { retrieveErrorFromApi } from '../../../helper'
import { setCancelReason } from '../../action'
import { setShowCancelOrder, setShowOrder } from '../../action/driver-monitor'
import { setKitchenConfig, setKitchenMonitorConfig, setKitchenMonitorConfigIsLoading, setKitchenMonitorConfigs, setKitchenOrders, setKitchenOrdersIsLoading, setKitchenOrdersLoading, setKitchenOrdersTotalLength, updateKitchenOrder } from '../../action/kitchenMonitor'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import getKitchenOrders from '../../axios/kitchen'
import getKitchenConfigs from '../../axios/kitchen/config/get'
import updateKitchenConfig from '../../axios/kitchen/config/put'
import updateKitchenOrderProductStatus from '../../axios/kitchen/orderProductStatus/put'
import setKitchenOrderStatus from '../../axios/kitchen/setKitchenOrderStatus'
import updateKitchenOrderDisplayStatus from '../../axios/kitchen/updateOrderDisplayStatus'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import getKitchenMonitorConfigs from '../../axios/kitchen/kitchenMonitorConfig/get'
import updateKitchenMonitorConfig from '../../axios/kitchen/kitchenMonitorConfig/put'
import createKitchenMonitorConfig from '../../axios/kitchen/kitchenMonitorConfig/post'
import deleteKitchenMonitorConfig from '../../axios/kitchen/kitchenMonitorConfig/delete'

export const getKitchenOrdersThunk = (page, size, kitchenMonitorConfigId, callbackSuccess) => dispatch => {
	return getKitchenOrders(page, size, kitchenMonitorConfigId)
		.then(res => {
			if (typeof callbackSuccess === 'function') {
				callbackSuccess(res.data[0])
			} else {
				dispatch(setKitchenOrders(res.data))

				if (res.headers['x-orders-total']) {
					dispatch(setKitchenOrdersTotalLength(parseInt(res.headers['x-orders-total'])))
				}
				dispatch(setKitchenOrdersIsLoading(false))
			}
		}).catch(error => {
			dispatch(setKitchenOrdersIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const setKitchenOrderStatusThunk = (order, status, reason) => dispatch => {
	return setKitchenOrderStatus(order.orderId, { status, reason })
		.then(() => {
			dispatch(setShowCancelOrder(false))
			dispatch(setShowOrder(false))
			dispatch(setCancelReason(null))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateKitchenOrderDisplayStatusThunk = (order, displayStatus) => dispatch => {
	dispatch(setKitchenOrdersLoading(true))
	dispatch(updateKitchenOrder({ ...order, displayStatus }))
	return updateKitchenOrderDisplayStatus(order.orderId, { displayStatus })
		.then((res) => {
			dispatch(updateKitchenOrder(res.data))
		}).catch(error => {
			dispatch(updateKitchenOrder(order))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
		.finally(() => {
			dispatch(setKitchenOrdersLoading(false))
		})
}

export const getKitchenMonitorConfigThunk = (companyIndex, monitorId) => dispatch => {
	return getKitchenMonitorConfigs(companyIndex)
		.then(res => {
			const data = monitorId ? res.data.find(item => item.id === monitorId) : res.data[0]
			dispatch(setKitchenMonitorConfigs(res.data))
			dispatch(setKitchenMonitorConfig(data))
			dispatch(setKitchenMonitorConfigIsLoading(false))
		}).catch(error => {
			dispatch(setKitchenMonitorConfigIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateKitchenMonitorConfigThunk = (companyIndex, monitorId, data, isSystemFormButton = true) => dispatch => {
	if (isSystemFormButton) {
		dispatch(setSystemFormButtonSave({ processing: true }))
	}
	return updateKitchenMonitorConfig(companyIndex, monitorId, data)
		.then(res => {
			if (isSystemFormButton) {
				dispatch(succeedSystemFormButtonSaveThunk())
			}
			dispatch(getKitchenMonitorConfigThunk(companyIndex, monitorId))
		}).catch(error => {
			if (isSystemFormButton) {
				dispatch(resetSystemFormButtonSaveThunk())
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getKitchenConfigThunk = (companyIndex) => dispatch => {
	return getKitchenConfigs(companyIndex)
		.then(res => {
			dispatch(setKitchenConfig(res.data?.[0]))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateKitchenConfigThunk = (companyIndex, id, data, callback) => dispatch => {
	return updateKitchenConfig(companyIndex, id, data)
		.then(res => {
			dispatch(setKitchenConfig(res.data))
			callback()
		}).catch(error => {
			callback()
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateKitchenOrderProductStatusThunk = (orderId, item, status) => dispatch => {
	return updateKitchenOrderProductStatus(orderId, item.itemPositionId, { status })
		.then((res) => {
			dispatch(updateKitchenOrder(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createKitchenMonitorConfigThunk = (companyIndex, data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return createKitchenMonitorConfig(companyIndex, data)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteKitchenMonitorConfigThunk = (companyIndex, id) => dispatch => {
	return deleteKitchenMonitorConfig(companyIndex, id)
		.then((res) => {
			dispatch(getKitchenMonitorConfigThunk(companyIndex))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
