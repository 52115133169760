import {
	ADD_NEW_RESTAURANT_ORDER, ADD_RESTAURANT_TABLES_WAITER_CALL, APPEND_RESTAURANT_ORDERS, RE_INITIALIZE_RESTAURANT, REMOVE_RESTAURANT_ORDER, SET_RESTAURANT_AREA_ID, SET_RESTAURANT_AREAS, SET_RESTAURANT_COUPON_ACTIVATE_MODAL, SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN, SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN, SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN, SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN, SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN, SET_RESTAURANT_ORDER_CHANGE_MODAL, SET_RESTAURANT_ORDER_MODAL,
	SET_RESTAURANT_ORDER_SPLIT_MODAL, SET_RESTAURANT_ORDERS, SET_RESTAURANT_ORDERS_CHANGE_MODAL, SET_RESTAURANT_ORDERS_MOVE, SET_RESTAURANT_ORDERS_TOTAL, SET_RESTAURANT_PAYMENT_MODAL, SET_RESTAURANT_PAYMENT_TYPE_MODAL, SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL, SET_RESTAURANT_SPLIT_ACTIONS_MODAL, SET_RESTAURANT_SPLIT_MODAL, SET_RESTAURANT_SWAP_MODAL, SET_RESTAURANT_TABLE, SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL, SET_RESTAURANT_TABLE_MODAL, SET_RESTAURANT_TABLES, SET_RESTAURANT_TABLES_LOADING, SET_RESTAURANT_TABLES_WAITER_CALLS, UPDATE_RESTAURANT_ORDER, UPDATE_RESTAURANT_ORDER_STATUS,
	UPDATE_RESTAURANT_TABLE, SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL, SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL, SET_RESTAURANT_IS_ANIMATION_STOPPED, SET_RESTAURANT_SHOW_RECEIPT_PAYER_DATA_MODAL, SET_RESTAURANT_SHOW_PAID_BEFORE_ORDERS_MODAL, SET_RESTAURANT_SHOW_ORDER_DATA_MODAL,
} from '../../actionTypes/restaurant'

const initialState = {
	areas: null,
	areaId: null,
	tables: null,
	tablesLoading: false,
	table: null,
	showTableModal: false,
	showPaymentModal: false,
	showOrderModal: false,
	showPaymentTypeModal: false,
	showSwapModal: false,
	showSplitModal: false,
	showSplitActionsModal: false,
	showOrderSplitModal: false,
	orders: [],
	ordersTotal: 0,
	move: [],
	modalChangeCalculatorIsNeeded: false,
	showTableClosedModal: false,
	modalChangeCalculatorIsOpen: false,
	modalTerminalButtonsIsOpen: false,
	modalTerminalPaymentProcessIsOpen: false,
	modalInvoiceConfigsIsOpen: false,
	modalInvoiceCustomerDataIsOpen: false,
	showCouponActivateModal: false,
	orderChangeModal: false,
	ordersChangeModal: false,
	showProcessPaymentErrorModal: false,
	tablesWaiterCalls: [],
	showSendToKitchenModal: false,
	showTableWaiterNotifiedModal: false,
	isAnimationStopped: false,
	showReceiptPayerData: false,
	showPaidBeforeOrdersModal: false,
	showOrderDataModal: false,
}

export function restaurantReducer (state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_RESTAURANT:
			return initialState
		case SET_RESTAURANT_AREAS:
			return {
				...state,
				areas: action.value
			}
		case SET_RESTAURANT_TABLES:
			return {
				...state,
				tables: action.value
			}
		case UPDATE_RESTAURANT_TABLE:
			const tablesCopy = state.tables ? [...state.tables] : []
			const tableIndex = tablesCopy.findIndex(table => table.id === action.value.id)
			if (tableIndex !== -1) {
				tablesCopy[tableIndex] = action.value
			}
			return {
				...state,
				tables: tablesCopy
			}
		case SET_RESTAURANT_TABLES_LOADING:
			return {
				...state,
				tablesLoading: action.value
			}
		case SET_RESTAURANT_AREA_ID:
			return {
				...state,
				areaId: action.value
			}
		case SET_RESTAURANT_TABLE:
			return {
				...state,
				table: action.value
			}
		case SET_RESTAURANT_TABLE_MODAL:
			return {
				...state,
				showTableModal: action.value
			}
		case SET_RESTAURANT_PAYMENT_MODAL:
			return {
				...state,
				showPaymentModal: action.value
			}
		case SET_RESTAURANT_PAYMENT_TYPE_MODAL:
			return {
				...state,
				showPaymentTypeModal: action.value
			}
		case SET_RESTAURANT_SWAP_MODAL:
			return {
				...state,
				showSwapModal: action.value
			}
		case SET_RESTAURANT_SPLIT_MODAL:
			return {
				...state,
				showSplitModal: action.value
			}
		case SET_RESTAURANT_SPLIT_ACTIONS_MODAL:
			return {
				...state,
				showSplitActionsModal: action.value
			}
		case SET_RESTAURANT_ORDER_MODAL:
			return {
				...state,
				showOrderModal: action.value
			}
		case SET_RESTAURANT_ORDER_SPLIT_MODAL:
			return {
				...state,
				showOrderSplitModal: action.value
			}
		case SET_RESTAURANT_ORDERS:
			return {
				...state,
				orders: action.value
			}
		case APPEND_RESTAURANT_ORDERS:
			return {
				...state,
				orders: state.orders ? state.orders.concat(...action.value) : action.value
			}
		case SET_RESTAURANT_ORDERS_TOTAL:
			return {
				...state,
				ordersTotal: action.value
			}
		case UPDATE_RESTAURANT_ORDER_STATUS:
			const ordersCopy = [...state.orders]
			ordersCopy.find(order => order.orderId === action.value.orderId).status.status = action.value.status
			return {
				...state,
				orders: ordersCopy
			}
		case ADD_NEW_RESTAURANT_ORDER:
			let ordersNewCopy = [...state.orders]
			ordersNewCopy.unshift(action.value.order)
			return {
				...state,
				orders: ordersNewCopy,
			}
		case UPDATE_RESTAURANT_ORDER:
			let ordersUpdateCopy = [...state.orders]
			const orderToUpdate = ordersUpdateCopy.find(order => order.orderId === action.value.order.orderId)
			if (orderToUpdate) {
				ordersUpdateCopy[ordersUpdateCopy.indexOf(orderToUpdate)] = action.value.order
			}
			return {
				...state,
				orders: ordersUpdateCopy,
			}
		case REMOVE_RESTAURANT_ORDER:
			let ordersRemovedCopy = [...state.orders]
			ordersRemovedCopy = ordersRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				orders: ordersRemovedCopy,
			}
		case SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED:
			return {
				...state,
				modalChangeCalculatorIsNeeded: action.value
			}
		case SET_RESTAURANT_ORDERS_MOVE:
			return {
				...state,
				move: action.value
			}
		case SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL:
			return {
				...state,
				showTableClosedModal: action.value
			}
		case SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN:
			return {
				...state,
				modalChangeCalculatorIsOpen: action.value
			}
		case SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN:
			return {
				...state,
				modalTerminalButtonsIsOpen: action.value
			}
		case SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN:
			return {
				...state,
				modalTerminalPaymentProcessIsOpen: action.value
			}
		case SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN:
			return {
				...state,
				modalInvoiceConfigsIsOpen: action.value
			}
		case SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN:
			return {
				...state,
				modalInvoiceCustomerDataIsOpen: action.value
			}
		case SET_RESTAURANT_COUPON_ACTIVATE_MODAL:
			return {
				...state,
				showCouponActivateModal: action.value
			}
		case SET_RESTAURANT_ORDER_CHANGE_MODAL:
			return {
				...state,
				orderChangeModal: action.value
			}
		case SET_RESTAURANT_ORDERS_CHANGE_MODAL:
			return {
				...state,
				ordersChangeModal: action.value
			}
		case SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL:
			return {
				...state,
				showProcessPaymentErrorModal: action.value
			}
		case SET_RESTAURANT_TABLES_WAITER_CALLS:
			return {
				...state,
				tablesWaiterCalls: action.value
			}
		case ADD_RESTAURANT_TABLES_WAITER_CALL:
			let tablesWaiterCallsCopy = [...state.tablesWaiterCalls]
			const tablesWaiterCallIndex = tablesWaiterCallsCopy.findIndex(item => item.waiterCallRefId === action.value.waiterCallRefId)
			if (tablesWaiterCallIndex === -1) {
				tablesWaiterCallsCopy.push(action.value)
			}
			return {
				...state,
				tablesWaiterCalls: tablesWaiterCallsCopy
			}
		case SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL:
			return {
				...state,
				showSendToKitchenModal: action.value
			}
		case SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL:
			return {
				...state,
				showTableWaiterNotifiedModal: action.value
			}
		case SET_RESTAURANT_IS_ANIMATION_STOPPED:
			return {
				...state,
				isAnimationStopped: action.value
			}
		case SET_RESTAURANT_SHOW_RECEIPT_PAYER_DATA_MODAL:
			return {
				...state,
				showReceiptPayerData: action.value
			}
		case SET_RESTAURANT_SHOW_PAID_BEFORE_ORDERS_MODAL:
			return {
				...state,
				showPaidBeforeOrdersModal: action.value
			}
		case SET_RESTAURANT_SHOW_ORDER_DATA_MODAL:
			return {
				...state,
				showOrderDataModal: action.value
			}
		default:
			return state
	}

}
