import { createSelector } from 'reselect'
import { calculateTotalDiscount, convert2ExtraDiscountItem } from '../../../component/Restaurant/areas/tables/modalPaymentType/helper'
import { calcCartItemTotalPrice, getPercentDiscountInCurrency, roundPrice } from '../../../helper'
import { selectUsername } from '../system'
import { selectTimerCounter } from '../timerCounter'
import { addMinutes } from 'date-fns'

export const selectRestaurantOrdersTable = store => store.restaurant.table?.orders
export const selectRestaurantSplitsTable = store => store.restaurant.table?.splits

export const selectGiftCouponInOrders = createSelector(
	selectRestaurantOrdersTable,
	(orders) => {
		if (orders?.length) {
			return !!orders[0].items?.find(item => item.type === 'GIFT_COUPON') || null
		}
		return null
	},
)

export const selectGiftCouponInSplit = createSelector(
	selectRestaurantSplitsTable,
	(splits) => {
		if (splits?.length) {
			return !!splits[0].items?.find(item => item.type === 'GIFT_COUPON') || null
		}
		return null
	},
)

export const selectGiftCouponOnTable = createSelector(
	selectGiftCouponInOrders,
	selectGiftCouponInSplit,
	(hasGiftCouponInOrders, hasGiftCouponInSplits) => {
		return hasGiftCouponInOrders || hasGiftCouponInSplits
	},
)

const selectRegisteredOrders = store => store.tableCart.orders
const selectNewOrder = store => store.tableCart.newOrder
const selectCartDiscount = store => store.cartDiscount?.discount

export const selectCartDiscountValue = (hasGiftCoupon) => createSelector(
	selectCartDiscount,
	(discount) => {
		return hasGiftCoupon ? 0 : discount
	},
)

export const selectCurrentOrdersInTableCart = createSelector(
	selectRegisteredOrders,
	selectNewOrder,
	(registeredOrders, newOrder) => {
		return newOrder ? [...registeredOrders, newOrder] : registeredOrders
	},
)

export const selectGiftCouponInTableCart = createSelector(
	selectCurrentOrdersInTableCart,
	(orders) => {
		if (orders?.length) {
			return !!orders[0].items?.find(item => item.type === 'GIFT_COUPON') || null
		}
		return null
	},
)

export const selectDiscountOnTable = (orders, hasGiftCoupon) => createSelector(
	selectCartDiscountValue(hasGiftCoupon),
	(discount) => {
		const ordersTotalWithDiscount = orders?.reduce((res, el) => res + el.items.reduce((tp, i) => tp + calcCartItemTotalPrice(i, true), 0), 0)
		return getPercentDiscountInCurrency(discount, ordersTotalWithDiscount)
	},
)

export const selectTotalPriceNewOrder = createSelector(
	selectNewOrder,
	selectGiftCouponInTableCart,
	selectCartDiscount,
	(newOrder, hasGiftCoupon, discount) => {
		const itemsTotalWithDiscount = newOrder?.items.reduce((tp, i) => tp + calcCartItemTotalPrice(i, true), 0)
		const discountValue = hasGiftCoupon ? 0 : discount
		const discountInCurrencyNewOrder = getPercentDiscountInCurrency(discountValue, itemsTotalWithDiscount)
		return roundPrice(newOrder?.totalPrice - discountInCurrencyNewOrder)
	},
)

export const selectTotalPriceOnTable = createSelector(
	selectRegisteredOrders,
	selectTotalPriceNewOrder,
	(registeredOrders, totalPriceNewOrder) => {
		const totalPriceRegisteredOrders = registeredOrders?.reduce((tp, o) => tp + o.totalPrice, 0)

		if (totalPriceNewOrder > 0) {
			return totalPriceRegisteredOrders + totalPriceNewOrder
		}
		return totalPriceRegisteredOrders
	},
)

const selectModalLoginUserName = store => store.modalLogin.username

export const selectProcessedBy = createSelector(
	selectModalLoginUserName,
	selectUsername,
	(modalLoginUserName, userName) => {
		return modalLoginUserName ? modalLoginUserName : userName
	},
)

const selectDishCoursesEnabled = store => store.restaurantSettings.dishCourses?.enabled
const selectDishCourseIndex = state => state.tableCart.selectedDishCourseIndex

export const selectSelectDishCourseIndex = createSelector(
	selectDishCoursesEnabled,
	selectDishCourseIndex,
	(dishCoursesEnabled, dishCourseIndex) => {
		return dishCoursesEnabled ? { index: dishCourseIndex } : null
	},
)

const selectPresetDiscounts = store => store.restaurantSettings.presetDiscounts
export const selectSelectedPresetDiscount = (presetDiscountId) => createSelector(
	selectPresetDiscounts,
	(presetDiscounts) => {
		return presetDiscounts.find(discount => discount.id === presetDiscountId) ?? null
	},
)

export const selectExtraDiscountItemOnTable = (presetDiscountId, totalPrice, customerDiscount, customerDiscountType) => createSelector(
	selectSelectedPresetDiscount(presetDiscountId),
	(selectedPresetDiscount) => {
		if (selectedPresetDiscount) {
			const presetDiscountPrice = calculateTotalDiscount(selectedPresetDiscount.discount, 'PERCENT', totalPrice)
			const presetDiscountName = `${selectedPresetDiscount.name} ${selectedPresetDiscount.discount}%`
			return convert2ExtraDiscountItem(presetDiscountId, presetDiscountName, presetDiscountPrice)
		} else {
			const customExtraDiscount = calculateTotalDiscount(customerDiscount, customerDiscountType, totalPrice)
			const customExtraDiscountName = 'Extra Rabatt'
			return convert2ExtraDiscountItem(null, customExtraDiscountName, customExtraDiscount)
		}
	},
)

const selectTablesWaiterCalls = state => state.restaurant.tablesWaiterCalls

export const selectActiveTablesWaiterCalls = createSelector(
	selectTablesWaiterCalls,
	selectTimerCounter,
	(tablesWaiterCalls, counter) => {
		return tablesWaiterCalls?.filter(item => {
			const currentTime = new Date()
			const waiterCallTimeout = addMinutes(new Date(item?.callTime), 7)
			return currentTime.getTime() < waiterCallTimeout.getTime()
		})
	},
)

export const selectTableWaiterCall = (table) => createSelector(
	selectActiveTablesWaiterCalls,
	(tablesWaiterCalls) => {
		return tablesWaiterCalls?.find(item => item.tablePublicId === table?.publicId) ?? null
	},
)

export const selectAreaWaiterCall = (area) => createSelector(
	selectActiveTablesWaiterCalls,
	(tablesWaiterCalls) => {
		return tablesWaiterCalls?.find(item => item.areaId === area?.id) ?? null
	},
)

const selectKitchenConfig = store => store.kitchenMonitor.config
export const selectRestaurantAreas = store => store.restaurant.areas
export const selectRestaurantAreaId = store => store.restaurant.areaId

export const selectIsSelectedItemsKitchenConfigMode = createSelector(
	selectKitchenConfig,
	(kitchenConfig) => {
		return kitchenConfig?.mode === 'SELECTED_ITEMS'
	},
)

export const selectRestaurantSelectedArea = createSelector(
	selectRestaurantAreas,
	selectRestaurantAreaId,
	(areas, areaId) => {
		return areas?.find(area => area.id === areaId) ?? null
	},
)

export const selectAreaHasWaiterNotifiedTable = (areaId) => createSelector(
	selectRestaurantAreas,
	(areas) => {
		return areas.find(area => area.id === areaId)?.waiterNotifiedItemsCount > 0
	},
)

export const selectRestaurantPaidBeforeOrdersTable = store => store.restaurant.table?.payBeforeOrders || []

export const selectAllTableOrders = createSelector(
	selectRestaurantOrdersTable,
	selectRestaurantSplitsTable,
	selectRestaurantPaidBeforeOrdersTable,
	(orders = [], splits = [], paidBeforeOrders = []) => {
		return [...orders, ...splits, ...paidBeforeOrders]
	},
)

export const selectTableWaiterNotifiedOrders = createSelector(
	selectAllTableOrders,
	(orders) => {
		return orders?.reduce((acc, order) => {
			const waiterNotifiedItems = order?.items?.filter(item => item?.kitchenMeta?.notificationStatus === 'WAITER_NOTIFIED')
			if (waiterNotifiedItems.length) {
				const item = { orderId: order.id, items: waiterNotifiedItems }
				acc.push(item)
			}
			return acc
		}, [])
	},
)

export const selectIsExistPaidBeforeOrdersOnTable = createSelector(
	selectRestaurantPaidBeforeOrdersTable,
	(paidBeforeOrders) => {
		return !!paidBeforeOrders.length
	},
)
export const selectIsOnlyPaidBeforeOrdersOnTable = createSelector(
	selectRestaurantOrdersTable,
	selectRestaurantSplitsTable,
	selectIsExistPaidBeforeOrdersOnTable,
	(orders, splits, isExistPaidBeforeOrdersOnTable) => {
		return isExistPaidBeforeOrdersOnTable && !orders.length && !splits.length
	},
)
