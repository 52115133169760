import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setRestaurantOrdersMove, setRestaurantSwapModal } from '../../../../../../redux/action/restaurant'
import { swapTableMoveThunk, swapTableThunk } from '../../../../../../redux/thunk/restaurant/table'
import ActionModal from '../../../../../Elements/modal/ActionModal'
import { selectProcessedBy } from '../../../../../../redux/selector/restaurant'
import RestaurantTable from '../../table'

const EVENT_Z_INDEX = 55

const SwapTableNew = React.memo(({ index, table }) => {
	const { table: selectedTable, move } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [swapActionModalIsOpen, setSwapActionModalIsOpen] = useState(false)
	const [tableToSwap, setTableToSwap] = useState(null)
	const processedBy = useSelector(selectProcessedBy)

	const handleSwapTable = useCallback((table) => {
		if (move && move.length) {
			dispatch(swapTableMoveThunk(selectedTable.id, { items: move, table: { id: table.id }, processedBy }))
		} else if (move !== null) {
			dispatch(swapTableThunk(selectedTable.id, { tableId: table.id, processedBy }))
		}

		dispatch(setRestaurantSwapModal(false))
		dispatch(setRestaurantOrdersMove([]))
	}, [move, selectedTable, dispatch, processedBy])

	const selectTable = useCallback(() => {
		const isExistPaidBeforeOrdersOnSelectedTable = table.payBeforeOrders?.length > 0

		if (table.orders.length || table.splits.length || isExistPaidBeforeOrdersOnSelectedTable) {
			if (table.orders?.[0]?.items?.[0].type === 'GIFT_COUPON' ||
				table.splits?.[0]?.items?.[0].type === 'GIFT_COUPON' ||
				isExistPaidBeforeOrdersOnSelectedTable) {
				return
			} else {
				setSwapActionModalIsOpen(true)
				setTableToSwap(table)
			}
		} else {
			handleSwapTable(table)
		}
	}, [table, handleSwapTable])

	const handleCloseSwapActionModal = useCallback(() => {
		setSwapActionModalIsOpen(false)
		setTableToSwap(null)
	}, [])

	const handleAcceptSwapTable = useCallback(() => {
		handleSwapTable(tableToSwap)
		handleCloseSwapActionModal()
	}, [tableToSwap, handleSwapTable, handleCloseSwapActionModal])

	return (
		<>
			<RestaurantTable key={index}
											 table={table}
											 index={index}
											 bgdColor={selectedTable?.id === table.id}
											 testId={`swapTable_${index + 1}`}
											 onClick={selectTable} />

			<ActionModal modalIsOpen={swapActionModalIsOpen}
									 accept={handleAcceptSwapTable}
									 cancel={handleCloseSwapActionModal}
									 close={handleCloseSwapActionModal}
									 zIndex={EVENT_Z_INDEX}
									 modalTitle={t('restaurant.tables.modal.swap')}
									 modalBody={t('restaurant.tables.modal.swap_table_text')} />
		</>
	)
})

SwapTableNew.displayName = 'SwapTableNew'
export default SwapTableNew
