import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import createTableOrder from '../../../axios/restaurant/table/order/post'
import fetchTableOrders from '../../../axios/restaurant/table/order'
import { removeTableCartOrderSubItem, setTableCartLoading, setTableCartOrders } from '../../../action/tableCart'
import closeTableOrders from '../../../axios/restaurant/table/order/closeOrders/post'
import printTableOrders from '../../../axios/restaurant/table/order/printOrders/post'
import { fetchRestaurantTablesThunk } from '../table'
import deleteTableOrderItem from '../../../axios/restaurant/table/order/deleteItem/delete'
import deleteTableOrderSubItem from '../../../axios/restaurant/table/order/deleteSubItem/delete'
import { setRestaurantShowProcessPaymentErrorModal, setRestaurantTableClosedErrorModal } from '../../../action/restaurant'
import { fetchPrintSettingsThunk } from '../../printReceipt'
import moveOrderToKitchen from '../../../axios/restaurant/table/order/moveToKitchen/put'
import updateOrderItemStatus from '../../../axios/restaurant/table/order/status/put'
import updateWaiterNotificationStatus from '../../../axios/restaurant/table/order/waiterNotificationStatus/put'
import { fetchTableSplitsThunk } from '../split'
import closePaidBeforeOrders from '../../../axios/restaurant/table/order/closePaidBeforeOrders/post'
import getOrder from '../../../axios/orderview/get'

export const fetchTableOrdersThunk = (tableId, ungroup, status) => dispatch => {
	return fetchTableOrders(tableId, ungroup, status)
		.then(res => {
			if (res.data) {
				return dispatch(setTableCartOrders(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createTableOrderThunk = (tableId, data, hasModalLogin, callbackSuccess) => (dispatch, getState) => {
	return createTableOrder(tableId, data, hasModalLogin)
		.then(res => {
			if (typeof callbackSuccess === 'function') {
				callbackSuccess()
			}
			dispatch(setTableCartLoading(false))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.table.areaId))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}).finally(() => dispatch(setTableCartLoading(false)))
}

export const closeTableOrdersThunk = (tableId, data, hasModalLogin) => async (dispatch, getState) => {
	try {
		const isEcTerminalPaymentMethod = data.paymentMethod === 'EC_TERMINAL'
		const isTap2PayPaymentMethod = data.paymentMethod === 'TAP_TO_PAY'
		const res = await closeTableOrders(tableId, data, hasModalLogin)
		if (!isEcTerminalPaymentMethod && !isTap2PayPaymentMethod) {
			dispatch(fetchPrintSettingsThunk(res.data.orderId, { module: 'RESTAURANT', businessScope: 'RESTAURANT' }))
		}
	} catch (error) {
		const errorStatusCode = error.response.data?.apierror.errorStatusCode
		if (errorStatusCode === 'terminal_payment_session_invalid') {
			const paymentEC = data.payments.find(payment => payment.method === 'EC_TERMINAL')
			/* eslint no-console: off */
			console.error(`Close table order: paymentId=${data.paymentId} totalPrice=${paymentEC?.total} companyIndex=${getState().login.companyIndex}`)
		} else if (errorStatusCode === 'table_order_is_not_exist') {
			return dispatch(setRestaurantTableClosedErrorModal(true))
		} else if (errorStatusCode === 'order_is_being_paid') {
			return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
		}
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
		throw new Error(error.response.data?.apierror?.message)
	}
}

export const printTableOrdersThunk = (tableId) => dispatch => {
	return printTableOrders(tableId)
		.then(res => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteTableOrderItemThunk = (tableId, orderId, itemPositionId, accessToken, hasAdminModalLogin) => dispatch => {
	dispatch(setTableCartLoading(true))
	return deleteTableOrderItem(tableId, orderId, itemPositionId, accessToken, hasAdminModalLogin)
		.then(res => {
			return dispatch(fetchTableOrdersThunk(tableId))
		}).catch(error => {
			const responseData = error?.response?.data
			const errorStatusCode = responseData?.apierror.errorStatusCode
			if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			} else if (responseData?.apierror?.status !== 'NOT_FOUND') {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			} else {
				// eslint-disable-next-line
				console.warn('Table delete item warn')
				return dispatch(fetchTableOrdersThunk(tableId))
			}
		}).finally(() => dispatch(setTableCartLoading(false)))
}

export const deleteTableOrderSubItemThunk = (tableId, orderId, itemPositionId, subItemPositionId, accessToken, hasAdminModalLogin, orderIndex, itemIndex, subItemIndex) => dispatch => {
	dispatch(setTableCartLoading(true))
	return deleteTableOrderSubItem(tableId, orderId, itemPositionId, subItemPositionId, accessToken, hasAdminModalLogin)
		.then(res => {
			return dispatch(fetchTableOrdersThunk(tableId))
		}).catch(error => {
			const responseData = error?.response?.data
			const errorStatusCode = responseData?.apierror.errorStatusCode
			if (responseData.apierror?.status === 'NOT_FOUND') {
				// eslint-disable-next-line
				console.warn('Table delete sub item warn')
				return dispatch(removeTableCartOrderSubItem({ orderIndex, itemIndex, subItemIndex }))
			} else if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			} else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		}).finally(() => dispatch(setTableCartLoading(false)))
}

export const moveTableOrderToKitchenThunk = (tableId, data, hasModalLogin) => (dispatch) => {
	return moveOrderToKitchen(tableId, data, hasModalLogin)
		.then(res => {
			dispatch(fetchTableOrdersThunk(tableId))
			dispatch(fetchTableSplitsThunk(tableId))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateTableOrderItemStatusThunk = (tableId, orderId, itemPositionId, status, hasModalLogin) => async (dispatch, getState) => {
	try {
		await updateOrderItemStatus(tableId, orderId, itemPositionId, status, hasModalLogin)
		dispatch(fetchTableOrdersThunk(tableId))
		dispatch(fetchTableSplitsThunk(tableId))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const updateTableWaiterNotificationStatusThunk = (tableId, data, hasModalLogin) => (dispatch, getState) => {
	return updateWaiterNotificationStatus(tableId, data, hasModalLogin)
		.then(res => {
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const closeTablePaidBeforeOrdersThunk = (tableId, hasModalLogin) => async (dispatch, getState) => {
	try {
		await closePaidBeforeOrders(tableId, hasModalLogin)
		return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const getRestaurantOrderThunk = (orderId) => async (dispatch) => {
	try {
		const res = await getOrder(orderId)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}
