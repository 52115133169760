import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setCustomer, setModalOrderCustomer } from '../../../../redux/action/customer'
import { setAppEventZIndex, setAppModal, setAppModalData } from '../../../../redux/action/system'
import { useNavigate } from 'react-router-dom'
import { registerOrderThunk, setOrderStatusThunk } from '../../../../redux/thunk/order/setOrderStatus'
import { setShowSingleDriver } from '../../../../redux/action/driver-monitor'
import { printOrderThunk } from '../../../../redux/thunk/order'
import { processUserThunk, restoreOrderThunk } from '../../../../redux/thunk/order/processUser'
import { setDeliveryCartCurrentDeliveryCost, setDeliveryCartItemFocusIndex, setDeliveryCartItems, setDeliveryCartOrder, setDeliveryCartOrderType } from '../../../../redux/action/deliveryCart'
import ButtonsModal from '../../../Elements/modal/ButtonsModal'
import { selectContainsAnyRole, selectHasAnyGroup } from '../../../../redux/selector/system'
import { getCouponThunk } from '../../../../redux/thunk/coupon'
import { clone, convert2CouponItem, reorderButtonsForUnpaidOrder, roundPrice } from '../../../../helper'
import { storeWoltDriverDeliveryPromisesThunk } from '../../../../redux/thunk/woltDriverIntegrations'
import { checkIsTodayOrder } from '../../../../helper/checkIsTodayOrder'
import { getExtraDiscountPrice } from '../../../../redux/selector/cart/helper'
import { setCartExtraDiscount } from '../../../../redux/action/cartDiscount'
import { ENTERTAINMENT_EXPENSES_RECEIPT_MIN_SUM } from '../../../Elements/modal/PayerDataModal/constants'
import { printRequestsThunk } from '../../../../redux/thunk/order/printRequests'
import { setSelectedOrder } from '../../../../redux/action/order'

const EVENT_Z_INDEX = 1

const CustomerOrderButtonsModal = React.memo(() => {
	const { t } = useTranslation()
	const selectedOrder = useSelector(store => store.order.selectedOrder)
	const showOrder = useSelector(store => store.customerStore.modal.showOrder)
	const hasRoleServiceWrite = useSelector(selectContainsAnyRole(['DELIVERY_SERVICE_WRITE']))
	const hasRoleCancelOrder = useSelector(selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT']))
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const isDelivery = selectedOrder?.orderType === 'DELIVERY'
	const isDeliveryByPlatform = selectedOrder?.orderType === 'DELIVERY_BY_PLATFORM'
	const partnerDeliveryMetaData = selectedOrder?.deliveryMetaData?.partner
	const isTakeawayDrive = partnerDeliveryMetaData?.type === 'TAKEAWAY_DRIVE'
	const isFoodoraDrive = partnerDeliveryMetaData?.type === 'FOODORA_DRIVE'
	const updatedDeliveryByPlatform = !!(isDelivery && partnerDeliveryMetaData?.type && (partnerDeliveryMetaData?.pickupEta || isTakeawayDrive))
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const isPartnerDriverOrder = partnerDeliveryMetaData?.deliveryId
	const isDeliveryPromiseId = partnerDeliveryMetaData?.deliveryPromiseId
	const showPayerDataModalForReceipt = roundPrice(selectedOrder?.totalPrice) >= ENTERTAINMENT_EXPENSES_RECEIPT_MIN_SUM

	const printOrderClick = useCallback(async () => {
		if (!selectedOrder.orderId) return
		await dispatch(printOrderThunk(selectedOrder.orderId, false))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder?.orderId])

	const printCanceledOrderClick = useCallback(() => {
		if (!selectedOrder.orderId) return
		dispatch(setModalOrderCustomer({ showPrintCanceledOrder: true }))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder?.orderId])

	const handleClose = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setAppEventZIndex(0))
	}, [dispatch])

	const showOrderDetails = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrderData: true }))
	}, [dispatch])

	const cancelOrder = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setModalOrderCustomer({ showCancelOrder: true }))
	}, [dispatch])

	const completeOrder = useCallback(async () => {
		await dispatch(setOrderStatusThunk(selectedOrder, 'COMPLETED'))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder])

	const confirmOrder = useCallback(() => {
		if (selectedOrder.preOrder && !isPlatformDriverOrder) {
			dispatch(setAppModal({ confirmPreOrderModalShow: true }))
			dispatch(setAppModalData({ confirmPreOrderModalData: [selectedOrder] }))
		} else if (isPlatformDriverOrder) {
			dispatch(setAppModal({ platformDriversOrderModalShow: true }))
			dispatch(setAppModalData({ platformDriversOrderModalData: [selectedOrder] }))
		} else if (selectedOrder.expressDelivery || selectedOrder?.expectedPickupTime) {
			dispatch(setAppModal({ confirmExpressOrderModalShow: true }))
			dispatch(setAppModalData({ confirmExpressOrderModalData: [selectedOrder] }))
		} else {
			dispatch(setAppModal({ processingTimeOrderModalShow: true }))
			dispatch(setAppModalData({ processingTimeOrderModalData: [selectedOrder] }))
		}
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, isPlatformDriverOrder, selectedOrder])

	const registerOrder = useCallback(async () => {
		await dispatch(registerOrderThunk(selectedOrder.orderId))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder?.orderId])

	const convertItemsWithCoupon = useCallback(async (items) => {
		let itemsCopy = clone(items)
		itemsCopy = itemsCopy.filter(item => item.type !== 'EXTRA_DISCOUNT')
		const resultItems = itemsCopy
			.filter(item => item.type !== 'MARKETING_COUPON')
			.map(item => {
				return item
			})
		const marketingCouponIndex = itemsCopy.find(item => item.type === 'MARKETING_COUPON')

		if (marketingCouponIndex) {
			const coupon = await dispatch(getCouponThunk(marketingCouponIndex.itemId))
			const convertItem = convert2CouponItem(coupon)
			return [...resultItems, convertItem]
		}

		return itemsCopy
	}, [dispatch])

	const changeOrder = useCallback(async () => {
		const preparedItems = await convertItemsWithCoupon(selectedOrder.items)
		const customer = selectedOrder.customer ? { ...selectedOrder.customer, deliveryCost: selectedOrder.deliveryCost?.cost } : null
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setCustomer(customer))
		const extraDiscount = getExtraDiscountPrice(selectedOrder.items)
		dispatch(setCartExtraDiscount(Math.abs(extraDiscount)))
		dispatch(setDeliveryCartOrderType(selectedOrder.orderType))
		dispatch(setDeliveryCartOrder(selectedOrder))
		dispatch(setDeliveryCartItems(preparedItems))
		dispatch(setDeliveryCartItemFocusIndex(null))
		dispatch(setDeliveryCartCurrentDeliveryCost(selectedOrder.deliveryCost?.cost))
		navigate('/delivery/cart')
	}, [dispatch, selectedOrder, convertItemsWithCoupon, navigate])

	const openSingleDriverModal = useCallback(() => {
		dispatch(setShowSingleDriver(true))
	}, [dispatch])

	const handlePayment = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setDeliveryCartOrder(selectedOrder))
		dispatch(setModalOrderCustomer({ showPaymentMethod: true }))
	}, [dispatch, selectedOrder])

	const removeProcessedBy = useCallback(async () => {
		await dispatch(processUserThunk(selectedOrder.orderId, null))
	}, [dispatch, selectedOrder?.orderId])

	const restoreOrder = useCallback(async () => {
		await dispatch(restoreOrderThunk(selectedOrder.orderId))
	}, [dispatch, selectedOrder?.orderId])

	const handlePrintEntertainmentExpensesReceipt = async () => {
		if (showPayerDataModalForReceipt) {
			dispatch(setModalOrderCustomer({ showReceiptPayerData: true }))
		} else {
			await dispatch(printRequestsThunk('ENTERTAINMENT_EXPENSES_RECEIPT', { orderId: selectedOrder.orderId }))
			dispatch(setSelectedOrder(null))
		}
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}

	const passOrderToPlatformCourier = useCallback(async () => {
		await dispatch(setOrderStatusThunk(selectedOrder, 'ON_THE_WAY'))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder])

	const passOrderToAnotherCourier = useCallback(async () => {
		await dispatch(storeWoltDriverDeliveryPromisesThunk(selectedOrder))
		dispatch(setModalOrderCustomer({ showOrder: false }))
	}, [dispatch, selectedOrder])

	const canBeRegisteredNow = useMemo(() => {
		if (!selectedOrder) return false
		const orderDate = selectedOrder.preOrder ? new Date(selectedOrder.preOrder) : new Date(selectedOrder.orderDate)
		const now = new Date()
		orderDate.setUTCHours(5, 0, 0, 0)
		return now > orderDate
	}, [selectedOrder])

	const isDenied = selectedOrder?.status?.status === 'DENIED'
	const isCanceled = selectedOrder?.status?.status === 'CANCELED'
	const isAccepted = selectedOrder?.status?.status !== 'NOT_ACCEPTED'
	const isRegistered = selectedOrder?.status?.status !== 'NOT_REGISTERED'
	const isIssuedToCourier = selectedOrder?.status?.status === 'ON_THE_WAY'
	const isClosed = !!selectedOrder?.zNumber
	const hasPlatform = !!selectedOrder?.platformMetaData
	const isRestaurant = selectedOrder?.orderType === 'RESTAURANT'
	const isPlatformOnlineShop = selectedOrder?.platformMetaData?.platformType === 'ONLINE_SHOP'
	const isNotPlatformOnlineShop = hasPlatform && selectedOrder?.platformMetaData?.platformType !== 'ONLINE_SHOP'
	const isEcTerminalOrder = selectedOrder?.payments?.find(p => p.method === 'EC_TERMINAL')
	const isPaid = selectedOrder?.payments?.filter(item => item?.method === selectedOrder?.paymentMethod)[0]?.payed
	const isInvoiceOrder = selectedOrder?.payments?.find(p => p.method === 'INVOICE')
	const isMoneyExchange = selectedOrder?.items?.find(item => item.type === 'CASH_EXCHANGE')
	const isMarketingCoupon = selectedOrder?.items?.find(item => item.type === 'MARKETING_COUPON')
	const orderDate = selectedOrder?.preOrder ? selectedOrder?.preOrder : selectedOrder?.orderDate
	const isPartiallyPaidWithGiftCoupon = !!selectedOrder?.payments?.find(p => p.method === 'GIFT_COUPON')
	const isUnpaidButPartiallyPaidWithCoupons = !isPaid && (isPartiallyPaidWithGiftCoupon || isMarketingCoupon)
	const isTodayOrder = checkIsTodayOrder(orderDate)

	const conditionForPrint = !isCanceled && !isDenied && isAccepted && isRegistered && isTodayOrder
	const conditionForChange = !isCanceled && !isDenied && !isNotPlatformOnlineShop && !isClosed && isRegistered && !isInvoiceOrder && !isMoneyExchange && !isUnpaidButPartiallyPaidWithCoupons

	const buttons = [
		{ icon: 'show', text: t('order.modal.show_order'), onClick: showOrderDetails, show: true },
		{ icon: 'print', text: t('order.modal.print_order'), onClick: printOrderClick, show:  conditionForPrint },
		{ icon: 'print', text: t('order.modal.print_order'), onClick: printCanceledOrderClick, show: isCanceled && !isClosed && isRegistered && isPaid },
		{ icon: 'cancel', text: t('order.modal.cancel_order'), onClick: cancelOrder, show: !isCanceled && !isDenied && isAccepted && !isClosed && isRegistered && hasRoleCancelOrder && isPaid && !isInvoiceOrder && !isMoneyExchange },
		{ icon: 'change', text: t('order.modal.change'), onClick: changeOrder, show: conditionForChange },
		{ icon: 'change', text: t('order.modal.change_payment'), onClick: handlePayment, show: !isCanceled && !isDenied && hasRoleServiceWrite && isAccepted && !isClosed && isRegistered && !isPlatformOnlineShop && !isPlatformDriverOrder && isPaid && !isInvoiceOrder && !isMoneyExchange },
		{ icon: 'user', text: t('order.modal.reserve_driver'), onClick: openSingleDriverModal, show: !isCanceled && !isDenied && isAccepted && !isClosed && isRegistered && !isPlatformDriverOrder && isPaid && !isMoneyExchange },
		{ icon: 'complete', text: t('order.modal.confirm_order'), onClick: confirmOrder, show: !isCanceled && !isDenied && hasPlatform && !isAccepted && !isClosed && isRegistered },
		{ icon: 'register', text: t('order.modal.register_order'), onClick: registerOrder, show: !isClosed && !isRegistered && canBeRegisteredNow },
		{ icon: 'complete', text: t('order.modal.complete_order'), onClick: completeOrder, show: !isCanceled && !isDenied && isAccepted && !isClosed && isRegistered && (!isPlatformDriverOrder || isTakeawayDrive || isFoodoraDrive) && isPaid && !isMoneyExchange },
		{ icon: 'no-user', text: t('order.modal.remove_processed_by'), onClick: removeProcessedBy, show: !isCanceled && !isDenied && isAccepted && selectedOrder?.processedBy && !isClosed && isRegistered && !isPlatformDriverOrder && isPaid && !isMoneyExchange },
		{ icon: 'check', text: t('order.modal.receipt'), onClick: handlePrintEntertainmentExpensesReceipt, show: !isCanceled && !isDenied && !isRestaurant && isAccepted && isRegistered && !isPlatformDriverOrder && isPaid && !isMoneyExchange },
		{ icon: 'repeat', text: t('order.modal.recover_order'), onClick: restoreOrder, show: isCanceled && !selectedOrder?.restored && !isClosed && isRegistered && !isPlatformDriverOrder && !isEcTerminalOrder && isPaid },
		{ icon: 'give-courier', text: t(`order.modal.give-courier.${selectedOrder?.platformMetaData?.platformType}`), onClick: passOrderToPlatformCourier, show: isPlatformDriverOrder && !isPartnerDriverOrder && !isTakeawayDrive && !isFoodoraDrive && !isCanceled && !isDenied && !isClosed && isRegistered && isAccepted && !isIssuedToCourier },
		{ icon: 'payment', text: t('order.modal.order_pay'), onClick: handlePayment, show: !isCanceled && !isDenied && !isClosed && isRegistered && isAccepted && !isPaid && isTodayOrder },
		{ icon: 'give-courier', text: t('order.modal.give_another_courier'), onClick: passOrderToAnotherCourier, show: isDeliveryPromiseId && isDelivery && !isDeliveryByPlatform && !isCanceled && !isDenied && !isClosed && isRegistered && isAccepted && !isIssuedToCourier },
	]

	const sortedButtons = isPaid ? buttons : reorderButtonsForUnpaidOrder(buttons, t)

	return (
		<ButtonsModal isOpen={showOrder}
									close={handleClose}
									id="delivery-order-buttons-modal"
									size="small"
									title={(selectedOrder?.orderDayCounter ?
										t('order.modal.number_order') + ' #' + selectedOrder?.orderDayCounter
										: t('order.modal.without_number'))}
									titlePrice={selectedOrder?.totalPrice}
									zIndex={EVENT_Z_INDEX}
									buttons={sortedButtons} />
	)
})

CustomerOrderButtonsModal.displayName = 'CustomerOrderButtonsModal'
export default CustomerOrderButtonsModal

