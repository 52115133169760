import { createSelector } from 'reselect'
import { getCurrencyLocaleByCountryCode, getCurrencySymbol, getFormattedCurrency, isDevEnvironment, isLiveEnvironment, jwtContainsAnyGroup, jwtContainsAnyRole, jwtGetCompanyData, jwtGetUsername } from '../../../helper'

export const selectEventZIndex = store => store.system.eventZIndex

export const selectIsNeededZIndex = (zIndex) => createSelector(
	selectEventZIndex,
	(eventZIndex) => {
		return eventZIndex === zIndex
	}
)

export const selectAccessToken = store => store.login.accessToken

export const selectHasAccessToken = store => !!store.login.accessToken

export const selectCompanyIndex = store => store.login.companyIndex

export const selectReactAppVersion = store => store.login.reactAppVersion

export const selectIsAuthorized = createSelector(
	selectAccessToken,
	selectCompanyIndex,
	(accessToken, companyIndex) => !!(accessToken && companyIndex)
)

export const selectContainsAnyRole = (roles) => createSelector(
	selectAccessToken,
	(accessToken) => {
		return !!jwtContainsAnyRole(accessToken, roles)
	}
)

export const selectHasAnyGroup = (groups) => createSelector(
	selectAccessToken,
	(accessToken) => {
		return !!jwtContainsAnyGroup(accessToken, groups)
	}
)

export const selectCompanyData = (param = null) => createSelector(
	selectAccessToken,
	(accessToken) => {
		if (param) {
			return jwtGetCompanyData(accessToken)?.[param]
		} else {
			return jwtGetCompanyData(accessToken)
		}
	}
)

export const selectUsername = createSelector(
	selectAccessToken,
	(accessToken) => jwtGetUsername(accessToken)
)

export const selectFormattedCurrency = (value) => createSelector(
	selectCompanyData('countryCode'),
	selectCompanyData('currency'),
	(countryCode, currencyCode) => {
		const currencyLocale = getCurrencyLocaleByCountryCode(countryCode)
		return typeof value === 'number' ? getFormattedCurrency(value, currencyLocale, currencyCode) : 'n/a'
	}
)

export const selectCurrencySymbol = createSelector(
	selectCompanyData('currency'),
	(currencyCode) => {
		return getCurrencySymbol(currencyCode)
	}
)

export const selectIsOldReactAppVersion = createSelector(
	selectReactAppVersion,
	version => {
		return version !== window.REACT_APP_VERSION
	}
)

export const selectIsTestCompany = createSelector(
	selectCompanyIndex,
	companyIndex => {
		if (isDevEnvironment()) {
			return ['FA-38264', '52-57403', 'F7-52113','8D-66054', '8A-56702', '65-41407', '88-42217', '38-87613',
				'94-52553', 'F9-26586', 'FF-81574', '26-46164', '11-67773', 'FF-81574', '3E-65108', '36-21165', '83-30206',
				'21-24324', '00-44342', 'ED-21660', '2A-37634', '6E-58866', '99-45474', '18-63453', '34-32278', '28-14347',
				'EE-68250', '7A-56005', '64-50271', '8C-47143', 'CB-56448', '67-64531', '27-76637', '5B-28554', '76-35016', '12-28778'].includes(companyIndex)

		} else {
			return ['7E-67147', 'FA-38264', '34-12483', 'D3-50576', '3E-11548', '18-11110', '3E-41313'].includes(companyIndex)
		}
	}
)

export const selectIsDevTestCompany = createSelector(
	selectCompanyIndex,
	companyIndex => {
		if (isDevEnvironment()) {
			return ['FA-38264', '52-57403', 'F7-52113', '8A-56702', '8D-66054', '65-41407', '88-42217', '38-87613',
				'94-52553', 'F9-26586', 'FF-81574', '26-46164', '11-67773', 'FF-81574', '3E-65108', '36-21165', '83-30206',
				'D3-50576', '21-24324', '00-44342', '2A-37634', '95-55086', '26-21201', '6E-58866'].includes(companyIndex)
		} else {
			return false
		}
	}
)

export const selectIsDateVTestCompany = createSelector(
	selectCompanyIndex,
	companyIndex => isLiveEnvironment() &&
		[
			'ED-24456', 'BF-41630', '1F-21065', '90-56403', 'F4-32310', 'D1-48162', '07-25145', 'F2-37031',
			'96-54250', '2C-14858', 'D9-43871', 'E3-82207', 'AB-76701', 'BC-27015', 'C9-85740', '40-68573', 'B0-48212', '77-36761',
			'68-08865', '3B-67230', '56-40226', 'CA-57457', 'F0-14776', 'BA-64820', '03-06884', 'AC-06375', 'C3-77674', 'DE-62314',
			'00-62422', 'D8-48145', '26-21201', '40-54252', '10-46087', '09-32042', 'A3-71354', '93-48772', '04-77674', '5C-15737',
			'53-58576', '78-11546', 'A9-27618', '54-73134', '5B-15180', '84-52238', '99-04148', 'BC-46286', 'CE-82752', '4B-88544',
			'BB-21527', 'BE-68217', '90-65861', 'F3-60224', '6B-46104', '57-33150', 'C5-24405', '32-70607', '9C-50782',
			'CD-23272', 'DD-46832', '5E-88426', '04-04528', '61-77484', 'FC-41270', '59-02738', '40-52040', '68-12818', 'F2-67005',
			'09-41007', 'DA-05367', 'BC-70257', '6D-13465', 'DA-67114', 'CD-62530', '64-76848', '07-37318', '5D-46177', '05-74383',
			'4C-52635', '78-75174', '7E-65422', 'E3-74855', 'E1-23352', '42-81556', '50-30573',
		].includes(companyIndex)
)

export const selectIsKioskTestCompany = createSelector(
	selectCompanyIndex,
	companyIndex => isLiveEnvironment() &&
		[
			'DE-55563', '9C-30210', 'F1-43781', 'E7-27837', 'B2-48737', '87-35761', '5C-52752', '7F-72581', '87-35761', '1E-15485', 'CA-08104',
			'FE-32050', '45-06316', '4B-88544', 'C2-26813', 'BA-71731',
		].includes(companyIndex)
)

